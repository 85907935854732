import crypto from "crypto-js"

export function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise<string>(resolve => {
        const reader = new FileReader()
        reader.onloadend = ev => {
            resolve(ev.target!.result as string)
        }
        reader.readAsDataURL(blob)
    })
}

export function blobToSha256(blob: Blob): Promise<string> {
    return new Promise<string>(resolve => {
        const reader = new FileReader()
        reader.onloadend = ev => {
            const result = crypto.enc.Base64.stringify(
                crypto.SHA256(
                    crypto.lib.WordArray.create(ev.target!.result as any)
                )
            )
            resolve(result)
        }
        reader.readAsArrayBuffer(blob)
    })
}
